<template>
    <div>
        <el-card>
            <template #header>
                <div style="text-align:left">
                    订单列表
                </div>
            </template>
            <div class="content">
                <span style="font-size:14px;margin-right:10px;">订单状态:</span>
                <el-select v-model="status" style="margin-right:10px;width:100px;" size="small">
                    <el-option label="全部" value="1"></el-option>
                    <el-option label="罚款" value="2"></el-option>
                    <el-option label="未罚款" value="3"></el-option>
                </el-select>
                <span style="font-size:14px;margin-right:10px;">逾期状态:</span>
                <el-select v-model="overdue" style="margin-right:10px;width:100px;" size="small">
                    <el-option label="全部" value="1"></el-option>
                    <el-option label="逾期" value="2"></el-option>
                    <el-option label="未逾期" value="3"></el-option>
                </el-select>
                <el-date-picker v-model="range" type="datetimerange" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts" style="margin-right:10px;" size="small" :default-time="defaulttime"></el-date-picker>
                <el-input v-model="keyword" placeholder="订单编号/客户名称" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
                <el-button type="primary" size="small">
                    <download-excel
                                    class="btn btn-default"
                                    :fetch="fetchData"
                                    :fields="json_fields"
                                    :before-generate="startDownload"
                                    :before-finish="finishDownload"
                                    worksheet="My Worksheet"
                                    type="csv"
                                    name="工资统计.csv">
                        <span style="padding: 11px 20px;margin: 11px -20px;">导出</span>
                    </download-excel>
                </el-button>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                          v-loading="load"
                          element-loading-spinner="el-icon-loading"
                          element-loading-text="加载中..."
                          :default-sort="{ prop: 'AddDTime', order: 'descending' }"
                          size="small">
                    <el-table-column label="订单编号" prop="OrderUID"></el-table-column>
                    <el-table-column label="业务员" prop="ywyname"></el-table-column>
                    <el-table-column label="客户名称" prop="Receiver"></el-table-column>
                    <el-table-column label="机价(元)" prop="Margin">
                        <template #default="scope">
                            <span v-if="scope.row.Margin == null">暂无</span>
                            <span v-else>{{ scope.row.Margin }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否逾期" prop="moneyoverdue">
                        <template #default='scope'>
                            <span v-if="scope.row.moneyoverdue == 0" style="color:red">否</span>
                            <span v-else style="color:#67c23a">是({{ scope.row.moneyoverdue }})天</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否罚款" prop="id">
                        <template #default='scope'>
                            <span v-if="scope.row.id == null" style="color:red">否</span>
                            <span v-else style="color:#67c23a">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="罚款金额(元)" prop="id">
                        <template #default='scope'>
                            <span v-if="scope.row.id != null" style="color:#67c23a">
                                {{ scope.row.deduct }}
                            </span>
                            <span v-else>0</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="下单时间" prop="AddDTime">
                        <template #default='scope'>
                            {{ scope.row.AddDTime }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="right">
                        <template #default='scope'>
                            <el-button size="small" v-if="scope.row.moneyoverdue > 29 && scope.row.id == null" @click="deduct(scope.row.id, scope.row.Margin, scope.row.penalty, scope.row.OrderUID)" type="danger">罚款</el-button>
                            <el-button size="small" v-if="scope.row.id != null" @click="undeduct(scope.row.id)" type="primary">解除罚款</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                               @size-change="handleSizeChange"
                               :current-page="curpage"
                               :page-size="pagesize"
                               :page-sizes="[10, 20, 30, 50, 100]"
                               layout="sizes,total, prev, pager, next, jumper"
                               :total="totalcount"
                               :hide-on-single-page="false"
                               style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
</template>
<script>
import { ElMessage } from 'element-plus'
import JsonExcel from "vue-json-excel3";
import constant from "@/constant"
export default {
    components: {
        downloadExcel: JsonExcel,
    },
    data() {
        return {
            json_fields: {
                "订单编号": "OrderUID",
                "业务员": "ywyname",
                "客户名称": "Receiver",
                "机价": "Margin",
                "是否逾期": {
                    field: "moneyoverdue", callback: (value) => {
                        if (value == 0) {
                            return `否`;
                        } else {
                            return `是`;
                        }
                    }
                },
                "是否罚款": {
                    field: "id", callback: (value) => {
                        if (value == null) {
                            return `是`;
                        } else {
                            return `否`;
                        }
                    }
                },
                "罚款金额": "deduct",
                "下单时间": "AddDTime",
            },
            tabledata: [],
            keyword: '',
            overdue: '',
            status: '',
            range: [],
            ywyid: "",
            shopstatus: '',
            curpage: '1',
            pagesize: '10',
            totalcount: "",
            merchanttype: ""
        }
    },
    methods: {
        async fetchData() {
            const response = await this.axios.get(constant.export5, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    keyword: this.keyword,
                    range: this.range,
                    overdue: this.overdue,
                    status: this.status,
                    ywyid: this.ywyid
                }
            })
            console.log(response.data.exportdata)
            if (response.data.exportdata.length == 0) {
                ElMessage({
                    type: "warning",
                    message: "暂无数据可下载",
                    center: true
                })
                return false;
            }
            return response.data.exportdata;
        },
        startDownload() {
            ElMessage({
                type: "warning",
                message: "开始下载",
                center: true,
            })
        },
        finishDownload() {
            ElMessage({
                type: "success",
                message: "下载完成",
                center: true,
            })
        },
        deduct(id, margin, penalty, OrderUID) {
            console.log(id)
            this.$confirm("确定要处罚该订单？", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.axios.get(constant.deduct, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        id: this.ywyid,
                        margin: margin,
                        penalty: penalty,
                        OrderUID: OrderUID
                    }
                }).then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success('操作成功');
                        this.$router.go(0)
                    } else {
                        this.$message.error("操作失败");
                    }
                });
            }).catch(() => {
                return false;
            })
        },
        undeduct(id) {
            this.$confirm("确定要解除处罚该订单？", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.axios.get(constant.undeduct, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        id: id
                    }
                }).then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success('操作成功');
                        this.$router.go(0)
                    } else {
                        this.$message.error("操作失败")
                    }
                });
            }).catch(() => {
                return false;
            })
        },
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val
            this.init();
        },
        init() {
            this.axios.get(constant.getdeduct, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    keyword: this.keyword,
                    range: this.range,
                    overdue: this.overdue,
                    status: this.status,
                    ywyid: this.ywyid
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
            });
        }
    },
    created: function () {
        this.ywyid = this.$route.query.id
        this.init();
    }
}
</script>